<template>
    <div class="aboutintro bg-icons">
        <div
            class="c w-xxl flex flex-wrap align-center"
            :class="[!about.introduction_image2 ? 'justify-center gap-xxl' : 'justify-between' ]"
        >
            <div class="aboutintro__text w-12 s:w-9 s:mx-auto s-down:text-center s-down:mb-xxl m-up:w-4">
                <h2 class="size-3">{{ about.introduction_title }}</h2>
                <div class="color-secondary" v-html="about.introduction_text" />

                <router-link :to="{ name: 'About' }" class="button bg-company-3 mt-xl">
                    {{ about.introduction_button_title || 'Lees meer' }}
                </router-link>
            </div>

            <div
                class="aboutintro__images w-11 s-down:pt-xxl s-down:mx-auto m-up:w-7 flex align-center"
                :class="{ 'l-up:w-4': !about.introduction_image2 }"
            >
                <div
                    v-if="about.introduction_image1"
                    class="aboutintro__image1 relative w-8 z-5"
                    :class="[about.introduction_image2 ? 'w-8' : 'w-12 m-down:w-11 m-down:ml-xl']"
                >
                    <div>
                        <img :srcset="imageSizes(cdnUrl(about.introduction_image1), [400, 800, 1200])" :alt="about.introduction_title" class="rounded-m" />
                    </div>
                    <img src="/img/ampersand.svg" alt="Mobers & Smolenaers Icon" class="aboutintro__ampersand" />
                </div>
                <div class="aboutintro__image2 w-5 z-1" v-if="about.introduction_image2">
                    <div>
                        <img :srcset="imageSizes(cdnUrl(about.introduction_image2), [400, 800, 1200])" :alt="about.introduction_title" class="rounded-m" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { imageSizes } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'AboutIntroduction',
    setup() {
        const { about } = useTexts();

        return {
            about,

            cdnUrl,
            imageSizes,
        };
    },
});
</script>

<style>
    .aboutintro {
        padding: 7vh 0;
    }
    .aboutintro__image1 > div,
    .aboutintro__image2 > div {
        position: relative;
    }

    .aboutintro__image1 > div::after,
    .aboutintro__image2 > div::after {
        content: '';
        display: block;
        padding-bottom: 72%;
    }
    .aboutintro__image2 > div::after {
        padding-bottom: 137%;
    }

    .aboutintro__image1 > div img,
    .aboutintro__image2 > div img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
        .aboutintro__image2 { margin-left: -8.3%; }

    .aboutintro__ampersand {
        position: absolute;
        left: 0;
        width: 20%;
        bottom: var(--size-xl);
        transform: translateX(-50%);
    }

</style>
