<template>
    <div v-for="article in articles" :key="article.id" :class="{
        'w-fake': mode === 'bar',
        'w-12 s-up:w-6 l-up:w-12': mode === 'sidebar',
        'w-12 s-up:w-6 l:w-4 xl:w-3': mode === 'overview',
    }">
        <router-link v-if="!excludeIDs.includes(article.id)"
            class="card bg-company-3 color-contrast relative flex flex-col hover:opacity-75 bg-icons--huis"
            :to="{ name: 'NewsArticle', params: { id: article.id, slug: kebabcase(article.name) || 'nieuws' } }" :class="{
                'justify-end card--hasbgimage': article.introduction_image,
                'card--nobgimage': !article.introduction_image,
                'mb-s': mode === 'sidebar',
            }">
            <img v-if="article.introduction_image"
                :srcset="imageSizes(cdnUrl(article.introduction_image), [400, 800, 1200])" :alt="article.name"
                class="z-1 absolute card__bgimage" />
            <div class="card__details color-primary relative z-5 card--px card--pt card--pb w-12"
                :class="{ 'flex flex-col flex-grow': !article.introduction_image }">
                <h3 class="size-4 mb-0">{{ article.name }}</h3>
                <div v-if="!article.introduction_image" v-html="article.introduction_text" class="flex-grow" />
                <div class="flex align-center cursor-pointer size-6">
                    <span>Lees meer</span>
                    <icon class="ml-s size-6">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"
                            viewBox="0 0 24 24">
                            <path d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                    </icon>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { imageSizes } from '@projectfive/services';
import kebabcase from 'lodash.kebabcase';
import { cdnUrl } from '@/services/cdnUrl';
import useLoading from '../composables/loading';
import { getLatestNews } from '../services/api';

export default defineComponent({
    name: 'NewsArticles',
    props: {
        mode: {
            type: String as PropType<'sidebar' | 'bar' | 'overview'>,
            default: 'bar',
        },
        excludeIDs: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        count: {
            type: Number as PropType<number>,
            default: 5,
        },
    },
    setup(props) {
        const articles = ref([]);
        const { loadStates } = useLoading();

        // @ts-ignore
        getLatestNews({ loadStates, count: props.count })
            .then((r) => {
                if (r) {
                    articles.value = r;
                }
            }).catch(() => { });

        return {
            articles,

            cdnUrl,
            imageSizes,
            kebabcase,
        };
    },
});
</script>
