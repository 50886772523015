
import { defineComponent, ref, PropType } from 'vue';
import { imageSizes } from '@projectfive/services';
import kebabcase from 'lodash.kebabcase';
import { cdnUrl } from '@/services/cdnUrl';
import useLoading from '../composables/loading';
import { getLatestNews } from '../services/api';

export default defineComponent({
    name: 'NewsArticles',
    props: {
        mode: {
            type: String as PropType<'sidebar' | 'bar' | 'overview'>,
            default: 'bar',
        },
        excludeIDs: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        count: {
            type: Number as PropType<number>,
            default: 5,
        },
    },
    setup(props) {
        const articles = ref([]);
        const { loadStates } = useLoading();

        // @ts-ignore
        getLatestNews({ loadStates, count: props.count })
            .then((r) => {
                if (r) {
                    articles.value = r;
                }
            }).catch(() => { });

        return {
            articles,

            cdnUrl,
            imageSizes,
            kebabcase,
        };
    },
});
