import { reactive, computed } from 'vue';

export interface loadStatesInt {
    states: Set<string>
    add: (string: string) => void;
    remove: (string: string) => void;
}

const loadStates = reactive<loadStatesInt>({
    states: new Set(),
    add,
    remove,
});

function add(val: string) {
    if (val) {
        loadStates.states.add(val);
    }
}

function remove(val: string) {
    if (val) {
        loadStates.states.delete(val);
    }
}

export default function useLoading() {
    const isLoading = computed(() => loadStates.states.size > 0);

    return {
        loadStates,
        isLoading,
    };
}
