
import { defineComponent, computed, PropType } from 'vue';
import kebabcase from 'lodash.kebabcase';
import useTexts from '../composables/texts';
import { visibleServices } from '../services/serviceFunctions';

export default defineComponent({
    name: 'ServiceCards',
    props: {
        mode: {
            type: String as PropType<'sidebar'|'bar'>,
            default: 'bar',
        },
    },
    setup() {
        const { services, texts } = useTexts();
        const serviceGroups = computed(() => visibleServices(services.value, texts.value.services_categories));
        const serviceGroupsOrder = ['huis', 'bedrijf', 'familie'];

        return {
            kebabcase,
            serviceGroups,
            serviceGroupsOrder,
        };
    },
});
