<template>
    <div class="services" :class="{ 'c w-l': mode === 'bar' }" id="services">

        <div
            class="services__intro mx-auto text-center mb-xxl w-12 s:w-9 m-up:w-6"
        >
            <h2 class="size-3 weight-black mb-0">{{ texts.services_title }}</h2>
            <div class="color-secondary" v-html="texts.services_text" />
        </div>

        <ServiceCards
            :mode="mode"
        />

    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import useTexts from '../composables/texts';
import ServiceCards from './ServiceCards.vue';

export default defineComponent({
    name: 'Services',
    components: {
        ServiceCards,
    },
    props: {
        mode: {
            type: String as PropType<'sidebar'|'bar'>,
            default: 'bar',
        },
    },
    setup() {
        const { texts } = useTexts();

        return {
            texts,
        };
    },
});
</script>

<style>
    #services {
        padding-top: 7vh;
        padding-bottom: 7vh;
    }
        .services__list a {
            padding: var(--size) calc(var(--card-px) - var(--size-s));
            border-radius: var(--radius);
            margin-bottom: var(--size-s);
        }
        .bg-company-1 .services__list a {
            background: var(--color-company-1--linkbg);
        }
        .bg-company-2 .services__list a {
            background: var(--color-company-2--linkbg);
        }
        .bg-company-3 .services__list a {
            background: var(--color-company-3--linkbg);
        }
</style>
