import * as Sentry from '@sentry/vue';

const excludedMode = 'development';
export function init({ version, app }) {
    if (process.env.NODE_ENV !== excludedMode) {
        Sentry.init({
            Vue: app,
            dsn: 'https://9f289077f5fc451397477077f7a410f0@o507582.ingest.sentry.io/5762912',
            release: version,
            autoSessionTracking: true,

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
            logErrors: true,
            ignoreErrors: ['ChunkLoadError'],
        });
    }
}

export function reportError(error, context = {}, type = 'error') {
    if (process.env.NODE_ENV !== excludedMode) {
        if (type === 'error') {
            Sentry.captureException(error, context);
        } else if (type === 'message') {
            Sentry.captureMessage(error, context);
        }
    } else {
        console.error(`Report ${type}:`, error, context);
    }
}

export function reportMessage(msg, context = {}) {
    reportError(msg, context, 'message');
}

export function updateMetadata({
    user,
}: {
    user: { id: number; email: string; name: string; lang: string };
}) {
    const {
        id, email, name: username, lang,
    } = user;
    if (process.env.NODE_ENV !== excludedMode) {
        Sentry.setUser({
            id: id.toString(),
            email,
            username,
            lang,
        });
    } else {
        console.debug('Set user', {
            id: id.toString(),
            email,
            username,
            lang,
        });
    }
}
