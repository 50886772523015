
import { defineComponent } from 'vue';
import Menu from '@/components/Menu.vue';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'Navigation',
    components: {
        Menu,
    },
    props: {
        onOpenMenu: Function,
    },
    setup() {
        const { settings } = useTexts();

        return {
            settings,
        };
    },
});
