
import { defineComponent } from 'vue';
import useTexts from '../composables/texts';
import NewsCards from './NewsCards.vue';
import useInView from '../composables/inView';

export default defineComponent({
    name: 'NewsArticles',
    components: {
        NewsCards,
    },
    setup() {
        const { texts } = useTexts();
        const { target, visible } = useInView();

        return {
            texts,

            target,
            visible,
        };
    },
});
