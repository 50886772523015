
import { defineComponent } from 'vue';
import { imageSizes } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'AboutIntroduction',
    setup() {
        const { about } = useTexts();

        return {
            about,

            cdnUrl,
            imageSizes,
        };
    },
});
