<template>
    <nav
        :class="{
            'm-up:contents s-down:hide': type === 'header',
            'bg-bg sidenav py-xxl px-xl overflow-auto m-up:hide bg-icons': type === 'menu',
        }"
        ref="el"
    >
        <button @click="$emit('close')" class="bg-company-3 -icon m-up:hide nav__close" aria-label="Open menu" tabindex="0">
            <icon class="size-5">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x" viewBox="0 0 24 24"><path d="M18 6L6 18M6 6l12 12"/></svg>
            </icon>
        </button>
        <router-link to="/diensten">
            Diensten
        </router-link>

        <router-link to="/over-ons">
            Over Ons
        </router-link>

        <router-link to="/nieuws">
            Nieuws
        </router-link>

        <router-link to="/contact" class="button bg-company-3 s-down:mt-xl m-up:ml-m">
            Contact
            <icon class="size-5">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            </icon>
        </router-link>
    </nav>
</template>

<script lang="ts">
import {
    defineComponent, PropType, ref, onBeforeUnmount, onMounted,
} from 'vue';
import { focusFirstTabIndex } from '@projectfive/services';

export default defineComponent({
    name: 'Menu',
    props: {
        type: {
            type: String as PropType<'header'|'menu'>,
        },
        onClose: Function,
    },
    setup() {
        /* #region Focus */
        const el = ref(null);
        let previousFocus;
        function setFocus() {
            previousFocus = document.activeElement;
            focusFirstTabIndex(el.value);
        }

        function unsetFocus() {
            if (previousFocus) {
                previousFocus.focus();
            }
        }
        onMounted(setFocus);
        onBeforeUnmount(unsetFocus);
        /* #endregion */

        return {
            el,
        };
    },
});
</script>

<style>
    .sidenav {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 60;
        height: 100%;
        width: 100%;
    }
        .sidenav a:not(.button):not(.logo) {
            display: flex;
            font-size: var(--size-3);
            padding: var(--size) 0;
        }
        @media (min-width: 34em) {
            .sidenav {
                box-shadow: var(--shadow-l);
                width: 90%;
                max-width: 38rem;
            }
        }
    .nav__close {
        top: var(--size-xxl);
        right: var(--size-xl);
        position: absolute;
    }
</style>
