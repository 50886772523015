import { ref, watch, onMounted } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

export default function useInView() {
    const target = ref(null);
    const targetIsVisible = ref(false);

    const { stop } = useIntersectionObserver(target, ([{ isIntersecting }]) => {
        targetIsVisible.value = isIntersecting;
    });

    const targetWasVisible = ref(false);
    const targetWasInvisible = ref(false);

    onMounted(() => {
        const watcher = watch(targetIsVisible, (val) => {
            if (val && targetWasInvisible.value) {
                targetWasVisible.value = true;
                watcher(); // stop watching
            } else {
                targetWasInvisible.value = true;
            }
        });
    });

    return {
        target,
        visible: targetIsVisible,
        wasVisible: targetWasVisible,
    };
}
