import { createApp } from 'vue';
import vMeta from '@projectfive/v-meta';
import defaultMetaData from '@/services/meta';
import { init as errorHandlingInit } from './services/errorhandling';

import App from './App.vue';
import router from './router';
import './assets/css/app.scss';

const app = createApp(App);
errorHandlingInit({ version: `${process.env.PACKAGE_VERSION}`, app });

app.use(router)
    .use(vMeta, { metadata: defaultMetaData })
    .mount('#app');
