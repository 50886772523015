import http from './http';
import { loadStatesInt } from '../composables/loading';
import { reportError } from './errorhandling';

export async function getTexts({ loadStates }: { loadStates?: loadStatesInt } = {}) {
    loadStates.add('get-texts');
    let response;
    try {
        response = await http.get('items/texts/1');
        if (response && response.results) {
            return {
                texts: response.results,
                about: response.results.about[0],
                services: response.results.services,
                settings: response.results.settings[0],
            };
        }
        throw new Error('invalid response');
    } catch (err) {
        console.error(err);
        reportError(err, { extra: { response }, tags: 'get-texts' });
        return false;
    } finally {
        loadStates.remove('get-texts');
    }
}

export async function getLatestNews({ loadStates, count = 5 }: { loadStates?: loadStatesInt, count?: number } = {}) {
    loadStates.add('get-latest-news');
    let response;
    try {
        response = await http.get(`items/news/?count=${count}&sort_by=id&sort_az=false`);
        if (response && response.results) {
            return response.results;
        }
        throw new Error('invalid response');
    } catch (err) {
        console.error(err);
        reportError(err, { extra: { response }, tags: 'get-texts' });
        return false;
    } finally {
        loadStates.remove('get-latest-news');
    }
}
