
import {
    defineComponent, PropType, ref, onBeforeUnmount, onMounted,
} from 'vue';
import { focusFirstTabIndex } from '@projectfive/services';

export default defineComponent({
    name: 'Menu',
    props: {
        type: {
            type: String as PropType<'header'|'menu'>,
        },
        onClose: Function,
    },
    setup() {
        /* #region Focus */
        const el = ref(null);
        let previousFocus;
        function setFocus() {
            previousFocus = document.activeElement;
            focusFirstTabIndex(el.value);
        }

        function unsetFocus() {
            if (previousFocus) {
                previousFocus.focus();
            }
        }
        onMounted(setFocus);
        onBeforeUnmount(unsetFocus);
        /* #endregion */

        return {
            el,
        };
    },
});
