<template>
    <footer class="bg-company-2 color-contrast" ref="target">
        <div v-if="$route.name !== 'Contact'" class="c w-xl mb-xxl pt-xxl flex flex-wrap justify-between">
            <div class="footer__column1 w-12 s:w-10 m:w-8 m-down:mx-auto text-center l-up:text-left mb-xxl l:w-4 xl:w-3">
                <h2 class="weight-black">{{ texts.contact_title }}</h2>
                <div class="color-secondary">{{ texts.contact_text }}</div>
            </div>
            <div class="footer__cards flex flex-wrap align-stretch w-12 s:w-10 s:mx-auto l-up:w-7 columns" :class="{ 'is-visible': visible }">
                <ContactCards />
            </div>
        </div>

        <div class="c w-xl py-m flex s-down:flex-col align-center footer__credits">
            <router-link to="/privacy" class="color-tertiary hover:color-primary pr-l">Privacy policy</router-link>

            <router-link to="/voorwaarden" class="color-tertiary hover:color-primary pr-l">Algemene voorwaarden</router-link>

            <span class="flex-grow xs:hide" />

            <a href="https://projectfive.nl" rel="nofollow noreferrer" class="flex align-center s-down:mt-l" title="Site by Project Five">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.1 24.6" class="w-24 h-24 color-tertiary hover:color-primary transition">
                    <path d="M18.3 0A1.8 1.8 0 0120 2.3l-5.7 21a1.8 1.8 0 11-3.5-1l5.7-21A1.8 1.8 0 0118.3 0z" fill="currentColor" />
                    <path
                        fill="currentColor"
                        d="M15.2 3.4l-.1.2a9.4 9.4 0 01-1.9 1.2 12.8 12.8 0 01-3 1 17.7 17.7 0 01-3.5.4 27.8 27.8 0 01-4.1-.3l-.9 6a18.6 18.6 0 013.4-1.7 10.2 10.2 0 013-.4 14.5 14.5 0 013 .3 10.4 10.4 0 012.1.7l-1 3.7a6.7 6.7 0 00-.4-1.5 4.3 4.3 0 00-1.7-2 5.1 5.1 0 00-2.7-.6 8 8 0 00-1.9.2 9.5 9.5 0 00-1.8.7 17 17 0 00-2 1.3H1L2.7 1.4a12 12 0 001.9.5 29.2 29.2 0 005 .4l2.5-.1a20.3 20.3 0 002.3-.3c.5 0 .9-.2 1.3-.3zm-11.4 13a2.3 2.3 0 01.8.8 2.2 2.2 0 01-1 3.2c-.4.2-.6.4-.6.6 0 .4.4.6 1.3 1a8.8 8.8 0 002.7.3 5.8 5.8 0 003.3-.8l-.4 1.3a18.4 18.4 0 01-2.3.1 14.7 14.7 0 01-3-.3 9 9 0 01-2.5-.8 4.4 4.4 0 01-1.6-1.5 3.2 3.2 0 01-.5-1.7 2.4 2.4 0 01.7-1.8 2.4 2.4 0 011.8-.7 2.5 2.5 0 011.3.3z"
                    />
                </svg>
            </a>
        </div>
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useTexts from '../composables/texts';
import ContactCards from './ContactCards.vue';
import useInView from '../composables/inView';

export default defineComponent({
    name: 'Footer',
    components: {
        ContactCards,
    },
    setup() {
        const { settings, texts } = useTexts();
        const { target, visible } = useInView();

        return {
            settings,
            texts,

            target,
            visible,
        };
    },
});
</script>

<style>
.footer__cards > div,
.contact__form > div {
    transition: 0.4s var(--anim-linkeasing);
    transition-delay: 0.4s;
}
.footer__cards > div:nth-child(2) {
    transition-delay: 0.5s;
}
.footer__cards:not(.is-visible) > div,
.contact__form:not(.is-visible) > div {
    transform: translate3d(0, -4rem, 0);
    opacity: 0;
    transition-delay: 0;
}
.contact__form:not(.is-visible) > div {
    transition-delay: 0.7s;
}
</style>
