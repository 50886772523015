const dayNames = [
    ['sunday', 'zondag', 'Zo'],
    ['monday', 'maandag', 'Ma'],
    ['tuesday', 'dinsdag', 'Di'],
    ['wednesday', 'woensdag', 'Wo'],
    ['thursday', 'donderdag', 'Do'],
    ['friday', 'vrijdag', 'Vr'],
    ['saturday', 'zaterdag', 'Za'],
];

function sortOpenDays(days = []) {
    const daysObject = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    };

    const openDays = days.reduce((acc, cur) => {
        cur = { from: 0, to: 2400, ...cur };
        cur.from_readable = timeToReadable(cur.from);
        cur.to_readable = timeToReadable(cur.to);

        if (typeof cur.day === 'string') {
            const weekday = dayNames.findIndex((it) => it.includes(cur.day.toLowerCase()));
            if (weekday > -1) {
                cur.dayIndex = weekday;
                acc[weekday].push(cur);
            }
        } else if (typeof cur.day === 'number' && cur.day < 7) {
            cur.dayIndex = cur.day;
            cur.day = dayNames[cur.day][1];
            acc[cur.day].push(cur);
        }
        return acc;
    }, daysObject);
    return openDays;
}

function sortTimesInDays(days = {}) {
    Object.keys(days).forEach((day) => {
        if (days[day].length > 1) {
            days[day].sort((a, b) => a.to - b.to);
        }
    });
    return days;
}

export function structureOpenDays(inputDays) {
    return sortTimesInDays(sortOpenDays(inputDays));
}

export function findNextOpenMoment(days, closedDays = []) {
    const excludedDays = closedDays.map((it) => timestampToDate({ timestamp: it, midnight: true, output: 'timestamp' }));
    const now = new Date();
    const weekday = now.getDay();
    const nowTime = mergeHoursAndMinutes(now.getHours(), now.getMinutes());

    for (let i = 0; i < 7; i += 1) {
        const dayNumber = (i + weekday) % 7;
        const dayMoments = days[dayNumber];

        if (dayMoments.length > 0) {
            let match;
            let nowOpen = false;
            if (dayNumber === weekday) {
                // today
                match = dayMoments.find((it) => it.to > nowTime);
                if (match && match.from < nowTime) {
                    nowOpen = true;
                }
            } else {
                match = dayMoments[0];
            }

            if (match && !isExcluded(i, excludedDays)) {
                return {
                    today: weekday === dayNumber,
                    nowOpen,
                    offset: i,
                    data: match,
                };
            }
        }
    }
    return false;
}

function mergeHoursAndMinutes(hours, minutes): number {
    let value = '0';
    if (minutes > 9) {
        value = `${hours}${minutes}`;
    } else {
        const processed_minutes = `00${minutes}`.substr(-2);
        value = `${hours}${processed_minutes}`;
    }
    return Number(value);
}

function isExcluded(offset, excluded) {
    if (excluded.length > 0) {
        const today = new Date();
        today.setDate(today.getDate() + offset);
        today.setHours(0, 0, 0, 0);
        const timestamp = today.getTime();

        return excluded.includes(timestamp);
    }
    return false;
}

function timestampToDate({ timestamp, midnight = true, output = 'date' }) {
    const value = timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
    const date = new Date(value);
    if (midnight) {
        date.setHours(0, 0, 0, 0);
    }
    if (output === 'timestamp') {
        return date.getTime();
    }
    return date;
}

function timeToReadable(val) {
    let time = val.toString();
    const len = time.length;
    if (len > 2) {
        time = `${time.substring(0, len - 2)}:${time.substring(len - 2)}`;
    }
    return time;
}
