<template>
    <div class="hero bg-company-2 color-contrast bg-icons" ref="target" :class="{ 'is-visible': visible, 'was-visible': wasVisible }">
        <div class="c w-xl flex flex-wrap align-center justify-between">
            <div class="hero__text w-12 m-up:w-7 mb-xl m-down:py-xxl m-down:my-xxl s-down:text-center">
                <h1
                    v-html="contrastText(texts.hero_title)"
                />
                <div class="color-secondary hero__description w-11 l-up:w-10" v-html="texts.hero_text" />

                <div class="buttons flex flex-wrap mt-xl s-down:justify-center">
                    <router-link to="/contact" class="button -l bg-company-3">
                        {{ texts.hero_button_1_title }}
                        <icon class="size-5">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                        </icon>
                    </router-link>
                    <router-link to="#services" class="button -l color-company-3 -ghost">
                        {{ texts.hero_button_2_title }}
                    </router-link>
                </div>
            </div>

            <div class="hero__image w-10 s:w-8 mx-auto m-up:w-4">
                <div>
                    <img src="/img/ampersand.svg" alt="Mobers & Smolenaers Icon" class="hero__ampersand z-5" />
                    <img :srcset="imageSizes(cdnUrl(texts.hero_image), [400, 800, 1200, 1600])" alt="Samen Beter Notarissen" class="hero__photo rounded-m z-1" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { imageSizes, contrastText } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';
import useInView from '../composables/inView';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'Hero',
    setup() {
        const { texts } = useTexts();
        const { target, visible, wasVisible } = useInView();

        return {
            texts,

            cdnUrl,
            imageSizes,
            contrastText,

            target,
            visible,
            wasVisible,
        };
    },
});
</script>

<style>
.hero {
    --margin: calc(1.5 * var(--size-xxl));
    margin-bottom: var(--margin);

    --anim-text-delay-initial: 0.3s;
    --anim-photo-delay-initial: 1.2s;
    --anim-amp-delay-initial: 1.6s;
}
.hero__text, .hero__text .buttons {
    transition: 0.4s var(--anim-linkeasing);
    transition-delay: var(--anim-text-delay-initial);
}
    .hero:not(.is-visible):not(.was-visible) .hero__text {
        opacity: 0;
        transition-delay: 0;
    }
    .hero__text .buttons {
        transition-delay: var(--anim-photo-delay-initial);
    }
    .hero:not(.is-visible):not(.was-visible) .buttons {
        transform: translate3d(0, -2rem, 0);
        opacity: 0;
        transition-delay: 0;
    }
.hero__text h1 strong {
    font-weight: inherit;
    color: var(--color-company-3);
}
    @media (min-width: 62em) {
        .hero__description {
            font-size: var(--size-4);
        }
    }
    .hero__image {
        margin-bottom: calc(-1 * var(--margin));
    }
    .hero__image > div {
        position: relative;
    }
    .hero__image > div::after {
        content: '';
        display: block;
        padding-bottom: 112%;
    }
    @media (min-width: 48em) {
        .hero__image > div::after {
            content: '';
            display: block;
            padding-bottom: 138%;
        }
    }
    .hero__photo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 0.8s var(--anim-linkeasing);
        transition-delay: var(--anim-photo-delay-initial);
    }
        .hero.was-visible .hero__photo {
            transition-delay: .4s;
            transition-duration: .6s;
        }
        .hero:not(.is-visible) .hero__photo {
            opacity: 0;
            transition-delay: 0;
        }
        .hero:not(.is-visible):not(.was-visible) .hero__photo {
            transform: translate3d(0, -4rem, 0);
        }
        .hero.was-visible:not(.is-visible) .hero__photo {
            transform: translate3d(0, 4rem, 0);
        }

    .hero__ampersand {
        position: absolute;
        left: 0;
        width: 25%;
        bottom: var(--size-xl);
        transform: translateX(-50%);
        transition: 0.4s var(--anim-linkeasing);
        transition-delay: var(--anim-amp-delay-initial);
    }
        .hero.was-visible .hero__ampersand {
            transition-delay: .4s;
        }
        .hero:not(.is-visible) .hero__ampersand {
            opacity: 0;
            transition-delay: 0;
        }
</style>
