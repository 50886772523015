
import { defineComponent } from 'vue';
import { imageSizes, contrastText } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';
import useInView from '../composables/inView';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'Hero',
    setup() {
        const { texts } = useTexts();
        const { target, visible, wasVisible } = useInView();

        return {
            texts,

            cdnUrl,
            imageSizes,
            contrastText,

            target,
            visible,
            wasVisible,
        };
    },
});
