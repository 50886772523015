<template>
    <header class="bg-company-2 color-contrast pt-l pb-r">
        <nav class="c w-xxl flex align-center">
            <router-link to="/" class="logo mr-xxl" aria-label="Naar homepage">
                <img
                    class="w-12 block ratio-stretch"
                    src="/img/logo_samen-beter-notarissen-mobers-smolenaers.svg"
                    :alt="settings && settings.company_name + ' logo'"
                    title="Naar homepage"
                    width="300"
                    height="77"
                />
                <h1 hidden>{{ settings && settings.company_name }}</h1>
            </router-link>

            <span class="flex-grow" />

            <Menu type="header" />
            <button @click="$emit('open-menu')" class="bg-company-3 -icon m-up:hide" aria-label="Open menu">
                <icon class="size-5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu" viewBox="0 0 24 24"><path d="M3 12h18M3 6h18M3 18h18"/></svg>
                </icon>
            </button>
        </nav>
    </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Menu from '@/components/Menu.vue';
import useTexts from '../composables/texts';

export default defineComponent({
    name: 'Navigation',
    components: {
        Menu,
    },
    props: {
        onOpenMenu: Function,
    },
    setup() {
        const { settings } = useTexts();

        return {
            settings,
        };
    },
});
</script>

<style>
    nav a:not(.button):not(.logo) {
        padding: 0 var(--size-r);
    }
    nav .logo {
        width: 80%;
        max-width: 21.5rem;
    }
</style>
