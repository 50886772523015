const metaSettings = {
    titleDefault: `${process.env.VUE_APP_NAME}`,
    titleSeparator: ' - ',
    title: '',
    description: '',
    image: `https://${process.env.VUE_APP_URL}/img/icons/android-chrome-512x512.png`,
    keywords: [],
    url: '',
    lang: 'nl',
    languages: ['nl'],
};

export default metaSettings;
