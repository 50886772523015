<template>
    <div
        class="color-secondary"
        :class="{
            'w-12 m:w-6 l:w-12 xl:w-6': mode === 'bar',
            'w-12 mb-r': mode === 'sidebar',
        }"
    >
        <div class="card card--px card--pt card--pb">
            <h3 class="size-4 mb-0">{{ settings.company_name }}</h3>
            <a
                v-if="settings.company_address && settings.company_address.parts"
                class="flex justify-between align-center"
                :href="'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(settings.company_address.formatted) + '&query_place_id=' + settings.company_address.place_id"
            >
                <div>
                    {{ settings.company_address.parts.street }} {{ settings.company_address.parts.number }}<br />
                    {{ settings.company_address.parts.zip }} {{ settings.company_address.parts.city }}
                </div>
                <icon class="size-5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin" viewBox="0 0 24 24"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"/><circle cx="12" cy="10" r="3"/></svg>
                </icon>
            </a>
            <template v-if="settings.company_postal_address && settings.company_postal_address.parts">
                <div class="mt-m">
                    {{ settings.company_postal_address.parts.street }} {{ settings.company_postal_address.parts.number }}<br />
                    {{ settings.company_postal_address.parts.zip }} {{ settings.company_postal_address.parts.city }}
                </div>
            </template>
        </div>
    </div>
    <div
        class="color-secondary"
        :class="{
            'w-12 m:w-6 l:w-12 xl:w-6': mode === 'bar',
            'w-12 mb-r': mode === 'sidebar',
        }"
    >
        <div class="card card--px card--pt card--pb">
            <a v-if="settings.company_phone" :href="'tel:' + settings.company_phone" class="block color-primary hover:color-company-3">
                <div class="flex justify-between align-center color-secondary">
                    <span>Telefoon:</span>
                    <icon class="size-5">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                    </icon>
                </div>
                <div class="size-4 weight-b">
                    {{ settings.company_phone }}
                </div>
            </a>

            <a v-if="settings.company_email" :href="'mailto:' + settings.company_email" class="my-l block color-primary hover:color-company-3">
                <div class="flex justify-between align-center color-secondary">
                    <span>E-mail:</span>
                    <icon class="size-5">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail" viewBox="0 0 24 24"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/><path d="M22 6l-10 7L2 6"/></svg>
                    </icon>
                </div>
                <div class="size-4 weight-b">
                    {{ settings.company_email }}
                </div>
            </a>

            <OpenHours
                v-if="settings.opening_hours && settings.opening_hours[0]"
                :data="settings.opening_hours[0].items"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import useTexts from '../composables/texts';
import OpenHours from './OpenHours.vue';

export default defineComponent({
    name: 'ContactCards',
    components: {
        OpenHours,
    },
    props: {
        mode: {
            type: String as PropType<'sidebar'|'bar'>,
            default: 'bar',
        },
    },
    setup() {
        const { settings } = useTexts();

        return {
            settings,
        };
    },
});
</script>
