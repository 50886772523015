import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/over-ons',
        name: 'About',
        component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/about/1/',
                params: { fieldset: 'website_fullpage' },
                news: false,
                services: true,
            },
        },
    },
    {
        path: '/diensten',
        name: 'Services',
        component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue'),
    },
    {
        path: '/diensten/:category/',
        name: 'ServiceCategory',
        component: () => import(/* webpackChunkName: "services" */ '../views/ServiceCategory.vue'),
        props: true,
    },
    {
        path: '/diensten/:category/:slug/:id',
        name: 'Service',
        component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
        props: true,
        meta: {
            article: {
                endpoint: 'items/services/[id]/',
                params: { fieldset: 'website_fullpage' },
                news: false,
                services: true,
            },
        },
    },
    {
        path: '/nieuws',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    },
    {
        path: '/nieuws/:slug/:id',
        name: 'NewsArticle',
        component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
        props: true,
        meta: {
            article: {
                endpoint: 'items/news/[id]/',
                params: { fieldset: 'website_fullpage' },
                news: true,
                services: false,
            },
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    },
    {
        path: '/voorwaarden',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/terms/1/',
            },
        },
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/privacy/1/',
            },
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        }
        return {
            top: 0,
            left: 0,
        };
    },
});

export default router;
