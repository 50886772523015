<template>
    <div class="services__cards flex flex-wrap columns justify-center align-stretch">
        <template
            v-for="(group, index) in serviceGroupsOrder"
            :key="group"
        >
            <div
                :class="{
                    'w-12 s-up:w-6 l-up:w-4': mode === 'bar',
                    'w-12 s-up:w-6 l-up:w-12': mode === 'sidebar',
                }"
                v-if="serviceGroups[group]"
            >
                <div
                    class="card color-contrast flex flex-col flex-grow fadeInDown"
                    :class="['bg-icons--' + group, 'bg-company-' + (index + 1)]"
                >
                    <router-link
                        class="pt-m card--px w-12 flex align-center justify-between"
                        :class="{ 'hover:color-company-3': index !== 2, 'hover:color-company-2': index === 2 }"
                        :to="{ name: 'ServiceCategory', params: { category: serviceGroups[group].name }}"
                    >
                        <div>
                            <h3 class="size-4 mb-0 color-current">{{ serviceGroups[group].title }}</h3>
                            <div class="color-secondary">{{ serviceGroups[group].subtitle }}</div>
                        </div>
                        <icon class="size-5 color-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right" viewBox="0 0 24 24"><path d="M5 12h14M12 5l7 7-7 7"/></svg>
                        </icon>
                    </router-link>
                    <div class="services__list mt-l mb-r flex-grow w-12 px-s">
                        <router-link
                            v-for="service in serviceGroups[group].items"
                            :key="service.id"
                            :to="{ name: 'Service', params: { category: serviceGroups[group].name, id: service.id, slug: kebabcase(service.name) }}"
                            class="flex justify-between align-center hover:color-company-2"
                        >
                            <span>{{ service.name }}</span>
                            <icon class="size-5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right" viewBox="0 0 24 24"><path d="M5 12h14M12 5l7 7-7 7"/></svg>
                            </icon>
                        </router-link>
                    </div>

                    <router-link
                        :to="{ name: 'ServiceCategory', params: { category: serviceGroups[group].name }}"
                        class="flex align-center card--pb card--px"
                        :class="{ 'hover:color-company-3': index !== 2, 'hover:color-company-2': index === 2 }"
                    >
                        <span>Bekijk meer</span>
                        <icon class="ml-l size-5">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right" viewBox="0 0 24 24"><path d="M5 12h14M12 5l7 7-7 7"/></svg>
                        </icon>
                    </router-link>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import kebabcase from 'lodash.kebabcase';
import useTexts from '../composables/texts';
import { visibleServices } from '../services/serviceFunctions';

export default defineComponent({
    name: 'ServiceCards',
    props: {
        mode: {
            type: String as PropType<'sidebar'|'bar'>,
            default: 'bar',
        },
    },
    setup() {
        const { services, texts } = useTexts();
        const serviceGroups = computed(() => visibleServices(services.value, texts.value.services_categories));
        const serviceGroupsOrder = ['huis', 'bedrijf', 'familie'];

        return {
            kebabcase,
            serviceGroups,
            serviceGroupsOrder,
        };
    },
});
</script>

<style>
    #services {
        padding-top: 7vh;
        padding-bottom: 7vh;
    }
        .services__list a {
            padding: var(--size) calc(var(--card-px) - var(--size-s));
            border-radius: var(--radius);
            margin-bottom: var(--size-s);
        }
        .bg-company-1 .services__list a {
            background: var(--color-company-1--linkbg);
        }
        .bg-company-2 .services__list a {
            background: var(--color-company-2--linkbg);
        }
        .bg-company-3 .services__list a {
            background: var(--color-company-3--linkbg);
        }
</style>
