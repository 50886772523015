
import { defineComponent, PropType } from 'vue';
import useTexts from '../composables/texts';
import OpenHours from './OpenHours.vue';

export default defineComponent({
    name: 'ContactCards',
    components: {
        OpenHours,
    },
    props: {
        mode: {
            type: String as PropType<'sidebar'|'bar'>,
            default: 'bar',
        },
    },
    setup() {
        const { settings } = useTexts();

        return {
            settings,
        };
    },
});
