import { Service, ServiceCategory } from '../types';

export function visibleServices(services: Service[], categories: ServiceCategory[]) {
    const servicesPerCat = 3;
    return services.reduce((acc, cur) => {
        if (cur.category) {
            const cat = getServiceCategory(categories, cur.category);
            if (acc[cat.name]) {
                if (acc[cat.name].items.length < servicesPerCat) {
                    acc[cat.name].items.push(cur);
                }
            } else if (cat) {
                acc[cat.name] = {
                    ...cat,
                    items: [cur],
                };
            }
        }
        return acc;
    }, {});
}

export function getServiceCategory(categories, id: number) {
    return categories.find((it) => it.id === id);
}
