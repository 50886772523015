
import { defineComponent, inject, ref } from 'vue';
import { Meta, METADATA } from '@projectfive/v-meta';
import { useRouter } from 'vue-router';
import { beforeEach } from '@/router/beforeEach';
import { cdnUrl } from '@/services/cdnUrl';
import Navigation from '@/components/Navigation.vue';
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';
import { setTexts } from './composables/texts';

export default defineComponent({
    name: 'App',
    components: {
        Meta,
        Navigation,
        Menu,
        Footer,
    },
    setup() {
        const menuOpened = ref(false);
        function closeMenu() {
            menuOpened.value = false;
        }
        function openMenu() {
            menuOpened.value = true;
        }

        const router = useRouter();
        router.beforeEach(async (to, from, next) => beforeEach({ to, next }, {
            onNavigation: [closeMenu],
        }));

        const metadata = inject(METADATA);
        setTexts().then(({ texts, settings }) => {
            // @ts-ignore
            metadata.setDefault({
                metadata: {
                    titleDefault: settings.company_name,
                    description: texts.hero_text,
                    image: texts.hero_image,
                },
                schemas: [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Organization',
                        name: settings.company_name,
                        description: texts.hero_text,
                        address: settings.company_address && settings.company_address.parts ? {
                            '@type': 'PostalAddress',
                            addressLocality: settings.company_address.parts.city,
                            postalCode: settings.company_address.parts.zip,
                            streetAddress: `${settings.company_address.parts.street} ${settings.company_address.parts.number}`,
                        } : {},
                        email: settings.company_email,
                        telephone: settings.company_phone,
                        logo: `https://${process.env.VUE_APP_URL}/img/logo_samen-beter-notarissen-mobers-smolenaers.svg`,
                        slogan: texts.hero_title.replace(/=/g, ''),
                        image: cdnUrl(texts.hero_image),
                        url: `https://${process.env.VUE_APP_URL}`,
                    },
                ],
            });
        });

        return {
            closeMenu,
            openMenu,
            menuOpened,
        };
    },
});
