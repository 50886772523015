export async function beforeEach({ to, next }, { onNavigation }) {
    next();
    if (Array.isArray(onNavigation)) {
        onNavigation.forEach((func) => {
            func();
        });
    }
}

export default beforeEach;
