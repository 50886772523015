<template>
    <template v-if="nextOpen.nowOpen">
        <span class="tag rounded color-primary bg-success px-r py-s"> Vandaag geopend tot {{ nextOpen.data.to_readable }} </span>
    </template>
    <template v-else-if="nextOpen.today">
        <span class="tag rounded color-primary bg-success px-r py-s"> Vandaag geopend vanaf {{ nextOpen.data.from_readable }} </span>
    </template>
    <template v-else>
        <span class="tag rounded color-primary bg-company-3 px-r py-s">
            {{ nextOpen.data.day }} geopend vanaf {{ nextOpen.data.from_readable }}
        </span>
    </template>
</template>

<script lang="ts">
import {
    defineComponent, PropType, ref, watch,
} from 'vue';
import { OpeningHours } from '../types';
import { structureOpenDays, findNextOpenMoment } from '../services/openHours';

export default defineComponent({
    name: 'OpenHours',
    props: {
        data: {
            type: Array as PropType<OpeningHours[]>,
        },
    },
    setup(props) {
        const openDays = ref(undefined);
        const nextOpen = ref(undefined);

        function setOpenDays() {
            if (props.data) {
                openDays.value = structureOpenDays(props.data);
                setNextOpen();
            }
        }

        function setNextOpen() {
            if (openDays.value) {
                nextOpen.value = findNextOpenMoment(openDays.value);
                setTimeout(setNextOpen, 60 * 1000);
            }
        }

        watch(() => props.data, setOpenDays, { immediate: true });

        return {
            nextOpen,
        };
    },
});
</script>
