import { InjectionKey, Ref } from 'vue';

export interface Obj {
    [name: string]: any;
}

export interface ServiceCategory {
    id: number;
    sort: number;
    name: string;
    title: string;
    subtitle: string;
    description: string;
    image1: string;
    image2: string;
}

export interface Texts {
    id: number;
    sort: number;
    contact_details: string;
    contact_notices: string;
    contact_text: string;
    contact_title: string;
    hero_button_1_title: string;
    hero_button_2_title: string;
    hero_image: string;
    hero_text: string;
    hero_title: string;
    news_text: string;
    news_title: string;
    services_text: string;
    services_title: string;
    services_categories: ServiceCategory[];
}

export interface About {
    id: number;
    sort: number;
    introduction_button_title: string;
    introduction_image1: string;
    introduction_image2: string;
    introduction_text: string;
    introduction_title: string;
    name: string;
}

export interface Service {
    id: number;
    name: string;
    category: number;
}

interface GmapsAddress {
    formatted: string;
    parts: {
        number: number;
        street: string;
        zip: string;
        city: string;
        country: string;
        country_code: string;
    };
    coords: {
        lat: number;
        lng: number;
    };
    place_id: string;
}

interface RulesGroup<T> {
    id: number;
    item: number;
    sort: number;
    items: T[];
}

export interface OpeningHours {
    id: number;
    sort: number;
    group: number;
    from: number;
    to: number;
    day: string;
}

export interface Settings {
    id: number;
    sort: number;
    company_address: GmapsAddress;
    company_email: string;
    company_name: string;
    company_phone: string;
    company_postal_address: GmapsAddress;
    opening_hours: RulesGroup<OpeningHours>[];
}

export const TEXTS: InjectionKey<Ref<Texts>> = Symbol('texts');
export const ABOUT: InjectionKey<Ref<About>> = Symbol('abouttexts');
export const SERVICES: InjectionKey<Ref<Service[]>> = Symbol('services');
export const SETTINGS: InjectionKey<Ref<Settings>> = Symbol('settings');
