import {
    inject, provide, ref,
} from 'vue';
import { getTexts } from '@/services/api';
import {
    TEXTS, ABOUT, SERVICES, SETTINGS, Texts, About, Service, Settings,
} from '../types';
import useLoading from './loading';

const storedTexts = ref<Texts>({} as Texts);
const storedAbout = ref<About>({} as About);
const storedServices = ref<Service[]>([] as Service[]);
const storedSettings = ref<Settings>({} as Settings);

export default function texts() {
    const remoteTexts = inject(TEXTS);
    const remoteAbout = inject(ABOUT);
    const remoteServices = inject(SERVICES);
    const remoteSettings = inject(SETTINGS);

    return {
        texts: remoteTexts,
        about: remoteAbout,
        services: remoteServices,
        settings: remoteSettings,
    };
}

export function setTexts() {
    const { loadStates } = useLoading();
    provide(TEXTS, storedTexts);
    provide(ABOUT, storedAbout);
    provide(SERVICES, storedServices);
    provide(SETTINGS, storedSettings);

    return new Promise((resolve, reject) => {
        getTexts({ loadStates })
            .then((r) => {
                if (r) {
                    storedTexts.value = r.texts;
                    storedAbout.value = r.about;
                    storedServices.value = r.services;
                    storedSettings.value = r.settings;

                    resolve({
                        texts: r.texts,
                        about: r.about,
                        services: r.services,
                        settings: r.settings,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
}
