
import { defineComponent, inject } from 'vue';
import { METADATA } from '@projectfive/v-meta';
import Hero from '@/components/Hero.vue';
import Services from '@/components/Services.vue';
import News from '@/components/News.vue';
import About from '@/components/About.vue';

export default defineComponent({
    name: 'Home',
    components: {
        Hero,
        Services,
        News,
        About,
    },
    setup() {
        const metadata = inject(METADATA);
        // @ts-ignore
        metadata.reset();
    },
});
