<template>
    <div id="news" class="bg-company-2 color-contrast pt-xxl" ref="target">
        <div class="flex flex-wrap">
            <div class="newsintro__text w-12 s:w-10 m:w-8 m-down:mx-auto text-center l-up:text-left mb-xxl l:w-4 xl:w-3 l-up:pr-xl">
                <h2 class="size-3 weight-black">{{ texts.news_title }}</h2>
                <div class="color-secondary" v-html="texts.news_text" />
            </div>

            <div class="newsintro__articles flex align-stretch w-12 l-up:w-8 flex-grow py-xxl l-up:pt-0 columns" :class="{ 'is-visible': visible }">
                <NewsCards />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useTexts from '../composables/texts';
import NewsCards from './NewsCards.vue';
import useInView from '../composables/inView';

export default defineComponent({
    name: 'NewsArticles',
    components: {
        NewsCards,
    },
    setup() {
        const { texts } = useTexts();
        const { target, visible } = useInView();

        return {
            texts,

            target,
            visible,
        };
    },
});
</script>

<style>
.newsintro__articles > div {
    width: 85vw;
    max-width: 38rem;
    flex-grow: 0;
    flex-shrink: 0;
    transition: 0.4s var(--anim-linkeasing);
    transition-delay: .4s;
}
    .newsintro__articles > div:nth-child(1) { transition-delay: .5s; }
    .newsintro__articles > div:nth-child(2) { transition-delay: .6s; }
    .newsintro__articles > div:nth-child(3) { transition-delay: .7s; }

.newsintro__text, .newsintro__articles {
    padding-left: calc(var(--width-xxl-sidepadding) + var(--container-gap));
    padding-left: max(var(--width-xxl-sidepadding), var(--container-gap));
}
.newsintro__articles {
    overflow-x: auto;
}

.newsintro__articles:not(.is-visible) > div {
    transform: translate3d(0, -4rem, 0);
    opacity: 0;
    transition-delay: 0;
}
</style>
